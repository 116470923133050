<template>
  <div class="lg:flex">
    <div class="lg:w-12/12 px-3">
      <h3 class="page-title">Welcome to the New LDI Carrier Portal</h3>
      <p class="subtitle">We've made some exciting new updates:</p>
      <ul class="custom-list">
        <li>Friendlier interface for easier payment lookup</li>
        <li>
          More payment transparency, including payment status and estimated pay
          dates
        </li>
        <li>Invoice generation and document uploading capabilities</li>
        <li>Accessibility from any device</li>
      </ul>
      <p>
        To get started, click on Payments at the top of the page to get started!
      </p>
      <p>
        Need help with a load?
        <router-link to="contact">Contact us </router-link> anytime.
      </p>
    </div>
  </div>
  <div class="lg:flex gap-3 mt-5">
    <div class="lg:w-6/12">
      <p class="subtitle">Recent Load Searches</p>
      <div v-if="loadingRL">
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
      </div>
      <div class="table-responsive">
        <DataTable
          :value="recentLoads"
          ref="dt"
          v-show="!this.loadingRL"
          v-if="isRLSuccessfull"
        >
          <Column field="PickupDate" header="Load Date" :sortable="true">
            <template #body="{ data }">
              {{
                data.PickupDate == null
                  ? (data.PickupDate = "Any Date")
                  : data.PickupDate
              }}
            </template>
          </Column>
          <Column field="OriginCity" header="Origin" :sortable="true">
            <template #body="{ data }">
              {{
                (data.OriginCity && data.OriginState && data.OriginRadius) ==
                null
                  ? (data.OriginCity = "Any")
                  : GetLocationText(
                      data.OriginCity,
                      data.OriginState,
                      data.OriginRadius
                    )
              }}
            </template>
          </Column>
          <Column field="DestinationCity" header="Destination" :sortable="true">
            <template #body="{ data }">
              {{
                (data.DestinationCity &&
                  data.DestinationState &&
                  data.DestinationRadius) == null
                  ? (data.DestinationCity = "Any")
                  : GetLocationText(
                      data.DestinationCity,
                      data.DestinationState,
                      data.DestinationRadius
                    )
              }}
            </template>
          </Column>
          <Column field="Weight" header="Equipment / Weight" :sortable="true">
            <template #body="{ data }">
              {{
                data.Van &&
                data.Reefer &&
                data.Flatbeds &&
                data.Specialized &&
                data.Size48 &&
                data.Size53 == false
                  ? (data.Weight = "Any")
                  : GetEquipmentText(data)
              }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="lg:w-6/12">
      <p class="subtitle">Recent Payments</p>
      <div v-if="loadingRP">
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
      </div>
      <div class="table-responsive">
        <DataTable
          :value="recentPayments"
          ref="dt"
          v-show="!this.loadingRP"
          v-if="isRPSuccessfull"
        >
          <Column field="LoadID" header="Load #" :sortable="true"></Column>
          <Column field="Lane" header="Lane" :sortable="true"> </Column>
          <Column field="DropDate" header="Delivery Date" :sortable="true">
            <template #body="{ data }">
              {{
                data.DropDate == "0001-01-01T00:00:00"
                  ? null
                  : formatDate(data.DropDate)
              }}
            </template>
          </Column>
          <Column
            field="PaymentDate"
            header="Date Paid"
            :sortable="true"
            dataType="date"
          >
            <template #body="{ data }">
              {{
                data.PaymentDate == "0001-01-01T00:00:00"
                  ? null
                  : formatDate(data.PaymentDate)
              }}
            </template>
          </Column>
          <Column field="Amount" header="Amount" :sortable="true">
            <template #body="{ data }">
              {{ Math.round(data.Amount) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { dataProvider } from "@/api/dataProvider";
import LoaderSkeleton from "@/components/common/LoaderSkeleton.vue";
export default {
  components: {
    LoaderSkeleton,
  },
  data() {
    return {
      recentLoads: [],
      recentPayments: [],
      CarrierID: this.$store.state.login.CarrierID,
      CarrierPortalUserID: this.$store.state.login.CarrierPortalUserID,
      EmailAddress: this.$store.state.login.EmailAddress,
      responseText: "",
      isRLSuccessfull: true,
      isRPSuccessfull: true,
      loadingRL: false,
      loadingRP: false,
    };
  },
  created() {
    this.getRecentLoads();
    this.getRecentPayment();
  },

  methods: {
    GetEquipmentText(lane) {
      var equipmentTypes = [];
      var displayText;
      if (lane.Van) equipmentTypes.push("Van");
      if (lane.Reefer) equipmentTypes.push("Reefer");
      if (lane.Flatbed) equipmentTypes.push("Flatbeds");
      if (lane.Specialized) equipmentTypes.push("Specialized");
      if (equipmentTypes.length > 0) displayText = equipmentTypes.join("/");
      else displayText = "Any ";
      var equipmentSizes = [];
      if (lane.Size48) equipmentSizes.push("48");
      if (lane.Size53) equipmentSizes.push("53");
      if (equipmentSizes.length > 0)
        displayText += "(" + equipmentSizes.join(" or ") + ")";
      else displayText += "(" + "any size trailer" + ")";
      if (lane.Weight != 0) displayText += lane.Weight + "lbs";
      return displayText;
    },
    GetLocationText(City, State, Radius) {
      var locationText = "";
      if (typeof City != "undefined" && City != "") {
        locationText += City;
      }
      if (typeof State != "undefined" && State != "") {
        if (locationText == "") locationText += State;
        else locationText += ", " + State;
      }
      if (locationText == "") {
        locationText += "Any";
      }
      if (
        typeof Radius != "undefined" &&
        Radius != "" &&
        locationText != "Any Location"
      ) {
        locationText += "(" + Radius + "m)";
      }
      return locationText;
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    async getRecentLoads() {
      this.loadingRL = true;
      var RecentLoads = await dataProvider.getRecentLoads(
        this.CarrierID,
        this.CarrierPortalUserID,
        this.EmailAddress
      );

      this.responseText = RecentLoads.ResponseText;
      this.recentLoads = RecentLoads.Searches;
      this.isRLSuccessfull = RecentLoads.IsSuccessful;
      this.loadingRL = false;
    },
    async getRecentPayment() {
      this.loadingRP = true;
      var RecentPayments = await dataProvider.getRecentPayment(
        this.CarrierID,
        this.CarrierPortalUserID,
        this.EmailAddress
      );

      this.responseText = RecentPayments.ResponseText;
      this.recentPayments = RecentPayments.Loads;
      this.isRPSuccessfull = RecentPayments.IsSuccessful;
      this.loadingRP = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  color: #7b7b7b;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
}
.custom-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  li {
    &:before {
      content: "\2713";

      display: inline-block;
      margin-right: 5px;
    }
  }
}
a {
  color: #3afe;
}
</style>
